<template>
  <div class="flex h-full flex-col">
    <title-row class="w-full grow-0">
      <div class="flex items-center justify-between">
        <h1 class="font-display text-2xl font-semibold">Test scheduling</h1>
        <base-spinner v-if="loading" />
        <p
          v-if="error"
          class="mb-2 inline-flex items-center font-semibold text-red-800"
        >
          <fv-icon icon="warning" class="mr-2" />
          {{ error }}
        </p>
      </div>
    </title-row>

    <form class="w-full space-y-4 py-4" @submit.prevent="">
      <div class="flex">
        <div class="w-1/3 p-2">
          <base-label for="test-schedule-service"> Service </base-label>
          <custom-select
            id="test-schedule-service"
            size="sm"
            label-size="sm"
            :value="serviceId"
            label-attribute="name"
            value-attribute="id"
            :options="services"
            @change="serviceId = $event"
          />
        </div>
        <div class="w-2/3 p-2">
          <base-label for="test-schedule-dates"> When? </base-label>
          <date-range-picker
            id="test-schedule-dates"
            ref="picker"
            v-model="dateRange"
            class="rounded bg-smoke-lightest p-1"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'YYYY-MM-DD' }"
            :show-dropdowns="true"
            :auto-apply="true"
            :time-picker="true"
            :time-picker24-hour="true"
            :always-show-calendars="true"
          >
            <template v-slot:input="picker">
              <div class="relative flex flex-row justify-between p-2 font-bold">
                <p>Start: {{ format(new Date(picker.startDate), dtFormat) }}</p>
                <p>End: {{ format(new Date(picker.endDate), dtFormat) }}</p>
              </div>
            </template>
          </date-range-picker>
        </div>
      </div>
      <base-button
        v-if="testScheduleAvailable"
        color="primary"
        class="w-1/3 pt-10"
        @click="createSchedule"
      >
        Generate schedule
      </base-button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { format, addHours } from 'date-fns';
import DateRangePicker from 'vue2-daterange-picker';

const dtFormat = 'yyyy-MM-dd HH:mm';
export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      loading: false,
      error: '',
      state: '',
      dateRange: {
        startDate: new Date(),
        endDate: addHours(new Date(), 4),
      },
      serviceId: 1,
      dtFormat,
    };
  },
  computed: {
    ...mapState('user', ['testScheduleAvailable']),
    ...mapState('admin/schedule', ['services']),
  },
  mounted() {
    if (!this.services.length) this.fetchServices();
  },
  methods: {
    ...mapActions('user', ['generateTestSchedule']),
    ...mapActions('admin/schedule', ['fetchServices']),
    async createSchedule() {
      this.loading = true;
      try {
        await this.generateTestSchedule({
          serviceId: this.serviceId,
          start: format(this.dateRange.startDate, `${dtFormat} z`),
          end: format(this.dateRange.endDate, `${dtFormat} z`),
        });
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    format,
  },
};
</script>
