var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-auto max-w-6xl" }, [
    _c(
      "div",
      {
        staticClass:
          "relative flex overflow-hidden rounded border bg-white shadow-sm",
        style: { maxHeight: "80vh" },
      },
      [
        _c("spinner-overlay", {
          attrs: {
            loading: _vm.uiState === "loading",
            color: "light",
            size: "xl",
          },
        }),
        _c("AccountNavBar", {
          style: { minHeight: "80vh", minWidth: "166px" },
          attrs: { buttons: _vm.buttons, selected: _vm.selected },
          on: {
            selected: function ($event) {
              _vm.selected = $event
            },
          },
        }),
        _vm.user
          ? _c(
              "div",
              {
                staticClass: "flex-1 overflow-auto p-8",
                style: { minHeight: "80vh" },
              },
              [
                _vm.selected === "General"
                  ? _c(
                      "div",
                      [
                        _c("AccountDetails", {
                          attrs: { user: _vm.user, "is-admin": _vm.isAdmin },
                          on: {
                            updated: function ($event) {
                              return _vm.handleUpdated("user", $event)
                            },
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "py-4" },
                          [
                            _vm.user.id
                              ? _c("AccountSection", {
                                  attrs: {
                                    title: "Roles",
                                    values: _vm.roles,
                                    "is-admin": _vm.isAdmin,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selected === "Vet licences",
                        expression: "selected === 'Vet licences'",
                      },
                    ],
                    staticClass: "flex-1",
                  },
                  [
                    _c("AccountLicences", {
                      attrs: {
                        "user-id": _vm.user.id,
                        "set-state-licences": _vm.stateLicences,
                        "is-admin": _vm.isAdmin,
                      },
                      on: {
                        updated: function ($event) {
                          return _vm.handleUpdated("stateLicences", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selected === "Work hours",
                        expression: "selected === 'Work hours'",
                      },
                    ],
                    staticClass: "flex-1",
                  },
                  [
                    _c("AccountWorkHours", {
                      attrs: {
                        "user-id": _vm.user.id,
                        "is-admin": _vm.isAdmin,
                        "set-work-hours": _vm.workHours,
                      },
                      on: {
                        updated: function ($event) {
                          return _vm.handleUpdated("workHours", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selected === "Languages",
                        expression: "selected === 'Languages'",
                      },
                    ],
                    staticClass: "flex-1",
                  },
                  [
                    _c("AccountLanguages", {
                      attrs: {
                        "user-id": _vm.user.id,
                        "set-languages": _vm.languages,
                        "is-admin": _vm.isAdmin,
                      },
                      on: {
                        updated: function ($event) {
                          return _vm.handleUpdated("languages", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selected === "Notifications",
                        expression: "selected === 'Notifications'",
                      },
                    ],
                  },
                  [
                    _c("AccountNotifications", {
                      attrs: {
                        "user-id": _vm.user.id,
                        "set-notifications": _vm.notifications,
                      },
                      on: {
                        updated: function ($event) {
                          return _vm.handleUpdated("notifications", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selected === "Animal types",
                        expression: "selected === 'Animal types'",
                      },
                    ],
                    staticClass: "flex-1",
                  },
                  [
                    _c("AccountAnimalTypes", {
                      attrs: {
                        "set-animal-types": _vm.animalTypes,
                        "user-id": _vm.user.id,
                        "is-admin": _vm.isAdmin,
                      },
                      on: {
                        updated: function ($event) {
                          return _vm.handleUpdated("animalTypes", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm.testScheduleAvailable
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selected === "Test schedule",
                            expression: "selected === 'Test schedule'",
                          },
                        ],
                        staticClass: "flex-1",
                      },
                      [_c("AccountTestSchedule")],
                      1
                    )
                  : _vm._e(),
                _vm.selected === "Password"
                  ? _c(
                      "div",
                      [
                        _c("AccountPassword", {
                          attrs: { email: _vm.user.email },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }