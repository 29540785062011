var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex h-full flex-col" },
    [
      _c("title-row", { staticClass: "w-full grow-0" }, [
        _c(
          "div",
          { staticClass: "flex items-center justify-between" },
          [
            _c("h1", { staticClass: "font-display text-2xl font-semibold" }, [
              _vm._v("Test scheduling"),
            ]),
            _vm.loading ? _c("base-spinner") : _vm._e(),
            _vm.error
              ? _c(
                  "p",
                  {
                    staticClass:
                      "mb-2 inline-flex items-center font-semibold text-red-800",
                  },
                  [
                    _c("fv-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "warning" },
                    }),
                    _vm._v(" " + _vm._s(_vm.error) + " "),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "form",
        {
          staticClass: "w-full space-y-4 py-4",
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "w-1/3 p-2" },
              [
                _c("base-label", { attrs: { for: "test-schedule-service" } }, [
                  _vm._v(" Service "),
                ]),
                _c("custom-select", {
                  attrs: {
                    id: "test-schedule-service",
                    size: "sm",
                    "label-size": "sm",
                    value: _vm.serviceId,
                    "label-attribute": "name",
                    "value-attribute": "id",
                    options: _vm.services,
                  },
                  on: {
                    change: function ($event) {
                      _vm.serviceId = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-2/3 p-2" },
              [
                _c("base-label", { attrs: { for: "test-schedule-dates" } }, [
                  _vm._v(" When? "),
                ]),
                _c("date-range-picker", {
                  ref: "picker",
                  staticClass: "rounded bg-smoke-lightest p-1",
                  attrs: {
                    id: "test-schedule-dates",
                    opens: "center",
                    "locale-data": { firstDay: 1, format: "YYYY-MM-DD" },
                    "show-dropdowns": true,
                    "auto-apply": true,
                    "time-picker": true,
                    "time-picker24-hour": true,
                    "always-show-calendars": true,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "input",
                      fn: function (picker) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "relative flex flex-row justify-between p-2 font-bold",
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "Start: " +
                                    _vm._s(
                                      _vm.format(
                                        new Date(picker.startDate),
                                        _vm.dtFormat
                                      )
                                    )
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "End: " +
                                    _vm._s(
                                      _vm.format(
                                        new Date(picker.endDate),
                                        _vm.dtFormat
                                      )
                                    )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.dateRange,
                    callback: function ($$v) {
                      _vm.dateRange = $$v
                    },
                    expression: "dateRange",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.testScheduleAvailable
            ? _c(
                "base-button",
                {
                  staticClass: "w-1/3 pt-10",
                  attrs: { color: "primary" },
                  on: { click: _vm.createSchedule },
                },
                [_vm._v(" Generate schedule ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }