<template>
  <div class="mx-auto max-w-6xl">
    <div
      class="relative flex overflow-hidden rounded border bg-white shadow-sm"
      :style="{ maxHeight: '80vh' }"
    >
      <spinner-overlay
        :loading="uiState === 'loading'"
        color="light"
        size="xl"
      />

      <AccountNavBar
        :buttons="buttons"
        :selected="selected"
        :style="{ minHeight: '80vh', minWidth: '166px' }"
        @selected="selected = $event"
      />

      <div
        v-if="user"
        class="flex-1 overflow-auto p-8"
        :style="{ minHeight: '80vh' }"
      >
        <div v-if="selected === 'General'">
          <AccountDetails
            :user="user"
            :is-admin="isAdmin"
            @updated="handleUpdated('user', $event)"
          />

          <div class="py-4">
            <AccountSection
              v-if="user.id"
              title="Roles"
              :values="roles"
              :is-admin="isAdmin"
            />
          </div>
        </div>

        <div v-show="selected === 'Vet licences'" class="flex-1">
          <AccountLicences
            :user-id="user.id"
            :set-state-licences="stateLicences"
            :is-admin="isAdmin"
            @updated="handleUpdated('stateLicences', $event)"
          />
        </div>

        <div v-show="selected === 'Work hours'" class="flex-1">
          <AccountWorkHours
            :user-id="user.id"
            :is-admin="isAdmin"
            :set-work-hours="workHours"
            @updated="handleUpdated('workHours', $event)"
          />
        </div>

        <div v-show="selected === 'Languages'" class="flex-1">
          <AccountLanguages
            :user-id="user.id"
            :set-languages="languages"
            :is-admin="isAdmin"
            @updated="handleUpdated('languages', $event)"
          />
        </div>

        <div v-show="selected === 'Notifications'">
          <AccountNotifications
            :user-id="user.id"
            :set-notifications="notifications"
            @updated="handleUpdated('notifications', $event)"
          />
        </div>

        <div v-show="selected === 'Animal types'" class="flex-1">
          <AccountAnimalTypes
            :set-animal-types="animalTypes"
            :user-id="user.id"
            :is-admin="isAdmin"
            @updated="handleUpdated('animalTypes', $event)"
          />
        </div>

        <div
          v-if="testScheduleAvailable"
          v-show="selected === 'Test schedule'"
          class="flex-1"
        >
          <AccountTestSchedule />
        </div>

        <div v-if="selected === 'Password'">
          <AccountPassword :email="user.email" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import AccountDetails from '@/components/account/AccountDetails';
import AccountSection from '@/components/account/AccountSection';
import AccountNavBar from '@/components/account/AccountNavBar';
import AccountPassword from '@/components/account/AccountPassword';
import AccountNotifications from '@/components/account/AccountNotifications';
import AccountAnimalTypes from '@/components/account/AccountAnimalTypes';
import AccountLanguages from '@/components/account/AccountLanguages';
import AccountWorkHours from '@/components/account/AccountWorkHours';
import AccountLicences from '@/components/account/AccountLicences';
import AccountTestSchedule from '@/components/account/AccountTestSchedule';
import { US_COUNTRY_ID } from '@/config/countries';
import { UserApi } from '@/api';

export default {
  components: {
    AccountDetails,
    AccountSection,
    AccountNavBar,
    AccountPassword,
    AccountNotifications,
    AccountAnimalTypes,
    AccountLanguages,
    AccountWorkHours,
    AccountLicences,
    AccountTestSchedule,
  },

  data() {
    return {
      selected: 'General',
      user: null,
      roles: [],
      languages: [],
      animalTypes: [],
      notifications: [],
      stateLicences: [],
      workHours: {},
      uiState: 'loading',

      buttonList: [
        {
          name: 'General',
          icon: 'user',
        },
        {
          name: 'Work hours',
          icon: 'clock',
        },
        {
          name: 'Languages',
          icon: 'settings',
        },
        {
          name: 'Notifications',
          icon: 'notification',
        },
        {
          name: 'Animal types',
          icon: 'pet',
        },
      ],
    };
  },

  computed: {
    ...mapState('user', ['testScheduleAvailable']),
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('user', { loggedInUserid: 'getUserId' }),
    buttons() {
      if (!this.user) return [];
      const buttons = [...this.buttonList];
      const usersOwnAccount = this.user.id === this.loggedInUserid;
      const usersCountryIsUS = this.user.country_id === US_COUNTRY_ID;

      if (usersOwnAccount) {
        buttons.push({
          name: 'Password',
          icon: 'settings',
        });
      }
      if (usersCountryIsUS || (this.isAdmin && usersCountryIsUS)) {
        buttons.splice(1, 0, {
          name: 'Vet licences',
          icon: 'google-maps',
        });
      }

      if (this.testScheduleAvailable) {
        buttons.push({
          name: 'Test schedule',
          icon: 'calendar',
        });
      }

      return buttons;
    },
  },
  mounted() {
    const id = this.$route.params.id || '';
    this.getUserDetails(id);
  },

  methods: {
    handleUpdated(key, data) {
      this[key] = data;
    },
    async getUserDetails(id) {
      const { data } = await UserApi.fetchUserDetails(id);

      this.user = data.user;
      this.user.jobTitle = data.jobTitle;

      this.roles = data.roles;
      this.languages = data.languages;
      this.animalTypes = data.animalTypes;
      this.notifications = data.notifications;
      this.stateLicences = data.usStateVetLicences;
      this.workHours = data.workHours;

      this.uiState = 'idle';
    },
  },
};
</script>
